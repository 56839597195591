import { UserRole } from './user-role';
import { Permission } from './user-access';
import { SelectOption } from '../form';
import { OutputData } from '../article';
import {
  CartRes,
  CostType,
  CustomSelectOption,
  QuantityType,
} from '../catalog';

export interface UserRegisterReq {
  email: string;
  pwd1: string;
  pwd2: string;
  firstName: string;
  lastName: string;
  tel: string;
  addressLocalNumber: string;
  addressStreet: string;
  addressPostCode: string;
  addressCity: string;
  addressCountry: string;
}

export interface LoginRes {
  _id: string;
  email: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  city: string;
  postCode: string;
  tel: string;
  recommended: string;
  isEnabled: boolean;
  isAccepted: UserAccountStatus;
  rulesAccepted: boolean;
  privacyPolicyAccepted: boolean;
  photoFn: string;
  lastLoginDt: Date;
  currentTokenId: string;
  changePassToken: string;
  changePassTokenValidTill: Date;
  updateToken: string;
  isCompanyFile: boolean;
  lastBadLoginAttemp: Date[];
  regularLoginDate: Date[];
  invitationId: string;
  loggedForFirstTime: boolean;
  superUser: SuperUserRes;
  boundData: string[];
  additionalInfo: AdditionalInfo;
  userModules: SelectOption[];
  permissions: ModPerm[];
  cartProducts: CartRes | null;
  totalBoughtProducts: number;
}

export interface AdditionalInfo {
  // @TODO maybe generic type?
  [key: string]: any;
}

export interface UserUpdateReq {
  email: string;
  firstName: string;
  lastName: string;
  tel: string;
  addressLocalNumber: string;
  addressStreet: string;
  addressPostCode: string;
  addressCity: string;
  addressCountry: string;
}

export interface UpdatePassRequest {
  oldPwd: string;
  newPwd: string;
  newPwd2: string;
}

export interface ResetPassLinkRequest {
  email: string;
}

export interface ResetPassRequest {
  newPwd: string;
  userId: string;
  updateToken: string;
}

export interface UserTableRes {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  tel: string;
  isEnabled: boolean;
  selectedSuperUser: boolean;
  isAccepted: UserAccountStatus;
  isImported: boolean;
  rulesAccepted: boolean;
  privacyPolicyAccepted: boolean;
  role: UserRole;
}

export interface ContactUsReq {
  email: string;
  firstName: string;
  lastName: string;
  content: string;
  tenantName?: string;
  tenantContactMail: string;
  tenantWebsite?: string;
}

export interface InviteUserReq {
  email?: string;
  firstName: string;
  lastName: string;
  tel?: string;
}

export interface InvitedUserRes {
  isNewInvitation: boolean
}

export interface DefaultUserFields {
  email: string;
  firstName: string;
  lastName: string;
  tel: string;
  type?: 'text' | 'select' | 'number' | 'checkbox' | 'date';
  name: string;
  default: boolean;
  isRequired: boolean;
  formSelectOptions?: string[];
}

export interface UserRes extends DefaultUserFields {
  _id: string;
}

export interface SuperUserRes {
  _id: string;
  firstName: string;
  lastName: string;
  tel: string;
  email: string;
  photoFn: string;
}

export interface InvitedUserTableRes {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  tel: string;
  isRegistered: boolean;
  isUsed: boolean;
}

export interface IdReq {
  _id: string | null;
}

export enum Language {
  pl = 'pl',
  en = 'en',
}

export enum UserAccountStatus {
  NoAction,
  Accepted,
  Rejected,
  Blocked,
  Inactive
}

export interface AddModeratorReq {
  firstName: string;
  lastName: string;
  email: string;
  tel: string;
  permissions: ModPerm[];
}

export interface ModPerm {
  value: Permission;
  label: string;
}

export interface EditModeratorReq {
  body: ModBody;
  content: ModAdditionalData;
}

export interface ModBody {
  firstName: string;
  lastName: string;
  email: string;
  tel: string;
}

export interface ModAdditionalData {
  moderatorData: SelectOption[];
  userModules: SelectOption[];
}

export interface ChangeMyPassReq {
  oldPwd: string;
  newPwd: string;
  newPwd2: string;
}

export interface ModeratorReq {
  email: string;
  firstName: string;
  lastName: string;
  tel: string;
  moderatorModules: SegmentSelectOption[];
  moderatorPermission: ModPerm[];
}

export interface SegmentSelectOption extends SelectOption {
  modulesAction: Permission[] | null;
}

export interface NotificationToken {
  token: string;
}

export interface ChangeRoleReq {
  role: UserRole;
  moderatorModules?: SegmentSelectOption[];
  moderatorPermission?: ModPerm[];
}

export type FilterTable = UserRole | 'acceptance' | 'rejected' | 'all';

export interface UserCart {
  _id: string;
  name: string;
  description: string;
  price: number;
  pointsPrice: number;
  costType?: CustomSelectOption<CostType>;
  quantityType: CustomSelectOption<QuantityType>;
  quantityInCart?: number;
  productCode: string;
  responsiblePersonEmail: string;
  uniqueProductCode?: string;
  isUsed: boolean;
  orderDate?: Date;
  productCategory: SelectOption[];
  externalUrl?: string;
  sumOfPointsPrice: number;
  sumOfPrice: number;
  cartPrice?: number;
  cartPointsPrice?: number;
}

export interface UserOrder {
  title: string;
  uniqueProductCode?: string;
  orderDate?: Date;
  responsiblePersonEmail: string;
  isUsed?: boolean;
}

interface MulterDiskUploadedFiles {
  [fieldname: string]:
  | {
    filename: string;
    size: number;
    mimetype: string;
    originalname: string;
    fieldname: string;
    encoding: string;
  }[]
  | undefined;
}

export interface ResetPwdReq {
  newPwd: string;
  newPwd2: string;
  userId: string;
  updateToken: string;
}

export interface ChangeUsersPwdReq {
  newPwd: string;
  newPwd2: string;
}

export interface SetNewPwdReq {
  login: string;
  oldPwd: string;
  newPwd: string;
  newPwd2: string;
}

export interface LinkedinRes {
  access_token: string;
  expires_in: number;
}

export interface BasicUserData {
  firstName: string;
  lastName: string;
  email: string;
  tel: string;
}
