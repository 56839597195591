import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

// Sentry.init({
//   dsn: 'https://081578fbf390490782da41bf1f4e878f@sentry.etd24.pl/108',
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.1,
// });

ReactDOM.render(<App />, document.getElementById('root'));
