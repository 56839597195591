import { MessageType, SMSStatRes } from '../communication';
import { CustomSelectOption } from '../catalog';

export enum LoggerModuleType {
  Article,
  Attachment,
  Auth,
  Communication,
  Contest,
  CustomBanner,
  CustomTile,
  Group,
  User,
  Catalog,
  Quiz,
  Presentation,
  TemporaryAction,
  Facebook,
  Linkedin,
  Twitter,
  ContactForm,
  Ranking,
  Ad,
  Static,
  ColumnConfig
}

export interface LoggerTableRes {
  _id: string;
  timestamp: Date;
  message: string;
  meta: MetaType;
}

export interface StatsRes {
  allInDB: number;
  registered: number;
  toVerify: number;
  smsStats: SMSStatRes;
}

export interface MetaType {
  email: string;
  tel: string;
  moduleType: LoggerModuleType;
  userId?: string;
  id?: string;
  [key: string]: any;
}

export interface ReportOption {
  value?: number;
  label: string;
}

export interface ReportRes {
  _id: string;
  text: string;
  segmentId: string;
  path: string;
  isDefault: boolean;
  availableReports: CustomSelectOption<ReportActions>[] | null;
  color: string;
}

export interface ReportArticleTableRes {
  _id: string;
  createdDt: Date;
  title: string;
}

export interface ReportCommunicationTableRes {
  _id: string;
  createdDt: Date;
  title: string;
  sendingDt: Date;
  messageType: MessageType;
  isPlanned: boolean;
  hasBeenSent: boolean;
}

export interface ReportRankingTableRes {
  _id: string;
  createdDt: Date;
  firstName: string;
  lastName: string;
}

export interface ReportContestTableRes {
  _id: string;
  startDt: Date;
  endDt: Date;
  title: string;
  address: string;
  contestStatus: boolean;
}

export enum ReportActions {
  ArticleAllWithDateRange = 'article_all_with_daterange',
  ArticleAllWithAvailableComments = 'article_all_with_available_comments',
  ArticleExportCommentsPerArticle = 'article_export_comments_per_article',
  CommunicationAllBySendingDate = 'communication_all_by_sending_date',
  CommunicationAllTypeSMS = 'communication_type_sms',
  CommunicationAllTypeMail = 'communication_type_mail',
  CommunicationAllTypePush = 'communication_type_push',
  CommunicationIsPlanned = 'communication_is_planned',
  CommunicationHasBeenSent = 'communication_has_been_sent',
  ContestAllByDateRange = 'contest_all_by_date_range',
  ContestByStatusCreated = 'contest_all_by_status_created',
  ContestByStatusInProgress = 'contest_all_by_status_in_progress',
  ContestByStatusFinished = 'contest_all_by_status_finished',
  ContestByStatusCancelled = 'contest_all_by_status_cancelled',
  ContestByStatusBlocked = 'contest_all_by_status_blocked',
  ContestByStatusDeleted = 'contest_all_by_status_deleted',
  ContestWithParticipants = 'contest_all_with_participants',
  UserRegisteredWithDateRange = 'user_registered_with_daterange',
  UserAllAccepted = 'user_all_accepted',
  UserAllActivated = 'user_all_activated',
  UserAllImported = 'user_all_imported',
  UserAllNotImported = 'user_all_not_imported',
  UserLoggedForTheFirstTime = 'user_logged_for_the_first_time',
  UserAllSuperUsers = 'user_all_super_users',
  CatalogAllByMoney = 'catalog_all_by_money_cost',
  CatalogAllByPoints = 'catalog_all_by_points_cost',
  CatalogAllByMixed = 'catalog_all_by_mixed_cost',
  CatalogAllByCategory = 'catalog_all_by_category',
  CatalogAllByResponsibleEmail = 'catalog_all_by_responsible_email',
  CatalogAllByPointsWithoutDeduction = 'catalog_all_by_points_without_deduction'
}

export enum AvailableChartModules {
  Article = 1,
  User = 2,
  Catalog = 3,
  Communication = 4,
  Contest = 5,
  Ranking,
}

export enum UserChartActions {
  RegisteredDt = 'registered_date',
}

export enum ArticleChartActions {
  CreatedDt = 'created_dt',
}

export enum CatalogOrderChartActions {
  CreatedDt = 'created_dt',
}

export enum CommunicationChartActions {
  CreatedDt = 'created_dt',
  SendingDt = 'sending_dt',
}

export enum PaymentsChartActions {
  CreatedDt,
}

export enum ContestChartActions {
  CreatedDt = 'created_dt',
  StartDt = 'start_dt',
  EndDt = 'end_dt',
}

export enum LogChartActions {
  UserLoggedSuccessfully = 'successfullyLoggedIntoApplication',
  UserLoggedOutSuccessfully = 'successfullyLoggedOutFromApplication',
  SentMail = 'sentMail',
  SentSMS = 'sentSMS',
  SentPush = 'sentPush',
  AddedArticle = 'addedArticle',
  EditedArticle = 'editedArticle',
  DownloadedAttachment = 'downloadedAttachment',
  RanQuizForFirstTime = 'ranQuizForFirstTime',
  StartedPresentation = 'startedPresentation',
  AddedProductToCart = 'addedProductToCart',
  BoughtProductsFromCart = 'boughtProductsFromCart',
  DeletedProductFromCart = 'deletedProductFromCart',
  ViewedSingleProduct = 'viewedSingleProduct',
  ClickedAd = 'clickedAd'
}

export interface ChartActionRes {
  name: string;
  uv: number;
}

export type ChartOptions =
  | typeof LogChartActions
  | typeof UserChartActions
  | typeof ArticleChartActions
  | typeof CatalogOrderChartActions
  | typeof CommunicationChartActions
  | typeof ContestChartActions;

export const LoggingMessage = {
  userLoggedSuccessfully: 'successfullyLoggedIntoApplication',
  userLoggedOutSuccessfully: 'successfullyLoggedOutFromApplication',
  invalidLoginData: 'typedWrongLoginData',
  userIsInactive: 'tryingToLogAsInactive',
  userIsRejected: 'tryingToLogAsRejected',
  userIsBlocked: 'tryingToLogAsBlocked',
  userTriesToLogInAndNeedsToResetPassword: 'userTriesToLogInAndNeedsToResetPassword',
  deletedArticle: 'deletedArticle',
  addedArticle: 'addedArticle',
  editedArticle: 'editedArticle',
  viewedArticleTable: 'viewedArticleTable',
  viewedArticleList: 'viewedArticleList',
  addedArticleComment: 'addedArticleComment',
  editedArticleComment: 'editedArticleComment',
  deletedArticleComment: 'deletedArticleComment',
  viewedAttachmentTable: 'viewedAttachmentTable',
  addedAttachment: 'addedAttachment',
  deletedAttachment: 'deletedAttachment',
  editedAttachment: 'editedAttachment',
  viewedSingleAttachment: 'viewedSingleAttachment',
  downloadedAttachment: 'downloadedAttachment',
  viewedCommunicationTable: 'viewedCommunicationTable',
  viewedTemplateTable: 'viewedTemplateTable',
  sentMail: 'sentMail',
  sentSMS: 'sentSMS',
  sentPush: 'sentPush',
  sentPushButTokensAreEmpty: 'sentPushButTokensAreEmpty',
  plannedMail: 'plannedMail',
  plannedSMS: 'plannedSMS',
  plannedPush: 'plannedPush',
  deletedCommunication: 'deletedCommunication',
  editedPlannedMail: 'editedPlannedMail',
  editedPlannedSMS: 'editedPlannedSMS',
  editedPlannedPush: 'editedPlannedPush',
  viewedSingleTemplate: 'viewedSingleTemplate',
  addedTemplate: 'addedTemplate',
  editedTemplate: 'editedTemplate',
  deletedTemplate: 'deletedTemplate',
  viewedSingleCommunication: 'viewedSingleCommunication',
  viewedContestTable: 'viewedContestTable',
  addedContest: 'addedContest',
  editedContest: 'editedContest',
  deletedContest: 'deletedContest',
  cancelledContest: 'cancelledContest',
  blockedContest: 'blockedContest',
  finishedContest: 'finishedContest',
  scannedQRCode: 'scannedQRCode',
  addedParticipant: 'addedParticipant',
  signUpToContest: 'signUpToContest',
  deletedParticipantFromContest: 'deletedParticipantFromContest',
  exportedContestParticipants: 'exportedContestParticipants',
  viewedContestParticipantsTable: 'viewedContestParticipantsTable',
  viewedQRCodeForScan: 'viewedQRCodeForScan',
  viewedOwnQRCode: 'viewedOwnQRCode',
  importedParticipants: 'importedParticipants',
  addedCustomBanner: 'addedCustomBanner',
  editedCustomBanner: 'editedCustomBanner',
  deletedCustomBanner: 'deletedCustomBanner',
  addedCustomTile: 'addedCustomTile',
  editedCustomTile: 'editedCustomTile',
  deletedCustomTile: 'deletedCustomTile',
  viewedGroupTable: 'viewedGroupTable',
  addedOrEditedGroup: 'addedOrEditedGroup',
  viewedSingleGroup: 'viewedSingleGroup',
  exportedGroup: 'exportedGroup',
  deletedGroup: 'deletedGroup',
  addedRules: 'addedRules',
  editedRules: 'editedRules',
  addedPrivacy: 'addedPrivacy',
  editedPrivacy: 'editedPrivacy',
  addedInfo: 'addedInfo',
  editedInfo: 'editedInfo',
  addedFAQ: 'addedFAQ',
  editedFAQ: 'editedFAQ',
  viewedUserTable: 'viewedUserTable',
  updatedOwnProfile: 'updatedOwnProfile',
  updatedSomeonesProfile: 'updatedSomeonesProfile',
  sentContactUsMsg: 'sentContactUsMsg',
  registrationPassDoesntMatch: 'registrationPassDoesntMatch',
  tryingToRegisterButUserExists: 'tryingToRegisterButUserExists',
  registeredSuccessfully: 'registeredSuccessfully',
  assignedSuperUserOnRegistration: 'assignedSUperUserOnRegistration',
  viewedUserDetails: 'viewedUserDetails',
  changedOwnPassword: 'changedOwnPassword',
  changedSomeonesPassword: 'changedSomeonesPassword',
  tryingToChangePassButPassesDoesntMatch:
    'tryingToChangePassButPassesDoesntMatch',
  tryingToChangePassButOldPassIsWrong: 'tryingToChangePassButOldPassIsWrong',
  addedSuperUser: 'addedSuperUser',
  addedAdmin: 'addedAdmin',
  addedModerator: 'addedModerator',
  editedModerator: 'editedModerator',
  assignedSuperUser: 'assignedSuperUser',
  acceptedProfile: 'acceptedProfile',
  rejectedProfile: 'rejectedProfile',
  enabledUser: 'enabledUser',
  disabledUser: 'disabledUser',
  importedUsers: 'importedUsers',
  exportedUsers: 'exportedUsers',
  allowedNotifications: 'allowedNotifications',
  changedUserRole: 'changedUserRole',
  addedCatalogProduct: 'addedCatalogProduct',
  editedCatalogProduct: 'editedCatalogProduct',
  addedCatalogCategory: 'addedCatalogCategory',
  editedCatalogCategory: 'editedCatalogCategory',
  deletedCatalogProduct: 'deletedCatalogProduct',
  deletedCatalogCategory: 'deletedCatalogCategory',
  addedProductToCart: 'addedProductToCart',
  boughtProductsFromCart: 'boughtProductsFromCart',
  deletedProductFromCart: 'deletedProductFromCart',
  viewedSingleProduct: 'viewedSingleProduct',
  updatedCartQuantity: 'updatedCartQuantity',
  addedQuiz: 'addedQuiz',
  editedQuiz: 'editedQuiz',
  viewedQuizDetails: 'viewedQuizDetails',
  deletedQuiz: 'deletedQuiz',
  ranQuizForFirstTime: 'ranQuizForFirstTime',
  continuedQuiz: 'continuedQuiz',
  viewedQuizResult: 'viewedQuizResult',
  addedPresentation: 'addedPresentation',
  editedPresentation: 'editedPresentation',
  deletedPresentation: 'deletedPresentation',
  startedPresentation: 'startedPresentation',
  finishedPresentation: 'finishedPresentation',
  viewedArticle: 'viewedArticle',
  addedTemporaryAction: 'addedTemporaryAction',
  editedTemporaryAction: 'editedTemporaryAction',
  deletedTemporaryAction: 'deletedTemporaryAction',
  addedPostOnFacebook: 'addedPostOnFacebook',
  addedPostAsPersonOnLinkedin: 'addedPostAsPersonOnLinkedin',
  addedPostAsPageOnLinkedin: 'addedPostAsPageOnLinkedin',
  updatedContactRequestStatus: 'updatedContactRequestStatus',
  receivedPoints: 'receivedPoints',
  exportedInvitations: 'exportedInvitations',
  tryingToInviteAlreadyRegisteredPerson:
    'tryingToInviteAlreadyRegisteredPerson',
  clickedAd: 'clickedAd',
  movedCursorOnAd: 'movedCursorOnAd',
  addedAd: 'addedAd',
  editedAd: 'editedAd',
  deletedAd: 'deletedAd',
  removedSuperUser: 'removedSuperUser',
  acceptedRules: 'acceptedRules',
  acceptedPrivacy: 'acceptedPrivacy',
  clearedPrivacyAcceptation: 'clearedPrivacyAcceptation',
  clearedRulesAcceptation: 'clearedRulesAcceptation',
  savedColumnConfig: 'savedColumnConfig',
  addedStatic: 'addedStatic',
  editedStatic: 'editedStatic',
  addedAllUsersToContest: 'addedAllUsersToContest',
  invitingPersonAlreadyInDatabase: 'invitingPersonAlreadyInDatabase',
  tryingToInviteAlreadyRegisteredPersonBySameUser:
  'tryingToInviteAlreadyRegisteredPersonBySameUser',
  invitingAgainBySamePerson: 'invitingAgainBySamePerson',
  tenantIsInactive: 'tenantIsInactive',
  exportedArticles: 'exportedArticles'
};
