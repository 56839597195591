import { v4 as uuid } from 'uuid';
import { ApiErrorCode } from 'app-types';
import { SingleNotification } from '../types/notifications';

interface HideNotification {
  type: 'HIDE_NOTIFICATION';
  payload: string;
}

interface SuccessNotification {
  type: 'SUCCESS_NOTIFICATION';
  payload: string;
}

interface ErrorNotification {
  type: 'ERROR_NOTIFICATION';
  payload: ApiErrorCode;
}

interface ErrorNotificationText {
  type: 'ERROR_NOTIFICATION_TEXT';
  payload: string;
}

type Action = HideNotification | SuccessNotification | ErrorNotification | ErrorNotificationText;

const initialState: SingleNotification[] = [];

export const getError = (error: ApiErrorCode) => {
  switch (error) {
    case ApiErrorCode.InvalidLoginData:
      return 'Niepoprawne dane logowania.';
    case ApiErrorCode.UserExists:
      return 'Użytkownik istnieje';
    case ApiErrorCode.UserNotFound:
      return 'Użytkownik nie istnieje';
    case ApiErrorCode.FormValidationError:
      return 'Błąd w formularzu';
    case ApiErrorCode.UpdateTokenExpired:
      return 'Token wygasł';
    case ApiErrorCode.NoSuchObjectWithThisId:
      return 'Nie znaleziono zasobou';
    case ApiErrorCode.ObjectNotUniuque:
      return 'Obiekt nie jest unikalny';
    case ApiErrorCode.NotUniuqueParama:
      return 'Parametr nie jest unikalny';
    case ApiErrorCode.InvalidOldPass:
      return 'Niepoprawne stare hasło';
    case ApiErrorCode.UserIsInactive:
      return 'Użytkownik jest nieaktywny';
    case ApiErrorCode.UserAlreadySolvedTest:
      return 'Użytkownik rozwiązał już quiz';
    case ApiErrorCode.InvalidLang:
      return 'Niepoprawny język';
    case ApiErrorCode.FileIsTooLarge:
      return 'Plik za duży max 10MB';
    case ApiErrorCode.ForebiddenFileFormat:
      return 'Niepoprawny format pliku';
    case ApiErrorCode.FileIsUndefined:
      return 'Nie znaleziono pliku';
    case ApiErrorCode.UserAlreadyVoted:
      return 'Już wypełniono';
    case ApiErrorCode.ConnectionLost:
      return 'Utracono połączenie';
    case ApiErrorCode.TenantExists:
      return 'Platforma została wcześniej dodana';
    case ApiErrorCode.CouldntGetConfiguration:
      return 'Nie udało się pobrać konfiguracji';
    case ApiErrorCode.CannotFindSegment:
      return 'Nie można znaleźć modułu';
    case ApiErrorCode.CannotFindTenantId:
      return 'Nie znaleziono platformy o podanym ID';
    case ApiErrorCode.QueryTenantIsNeeded:
      return 'Niepoprawny identyfikator platformy';
    case ApiErrorCode.GroupIsEmpty:
      return 'Grupa jest pusta';
    case ApiErrorCode.FillRequiredFormFields:
      return 'Uzupełnij wymagane pola';
    case ApiErrorCode.WrongEmailFormat:
      return 'Niepoprawny format emaila';
    case ApiErrorCode.TenantIsDeactivated:
      return 'Platforma jest wyłączona';
    case ApiErrorCode.UserIsRejected:
      return 'Użytkownik został odrzucony';
    case ApiErrorCode.AccountIsBlocked:
      return 'Konto zablokowane';
    case ApiErrorCode.WrongTelFormat:
      return 'Niepoprawny numer telefonu';
    case ApiErrorCode.ContestHasParticipant:
      return 'Jesteś już zarejestrowany!';
    case ApiErrorCode.ContestHasStatusThatCannotBeDeleted:
      return 'Określone wydarzenie nie może być usunięte';
    case ApiErrorCode.UserIsAlreadyAssigned:
      return 'Użytkownik zapisany';
    case ApiErrorCode.WrongQRCode:
      return 'Niepoprawny Kod QR!';
    case ApiErrorCode.ParticipantCannotBeFound:
      return 'Nie odnaleziono uczestnika!';
    case ApiErrorCode.PhoneNumberRequiresCountryCode:
      return 'Wymagany prefiks krajowy w polu: Numer';
    case ApiErrorCode.WrongCSVHeaders:
      return 'Niepoprawne nagłówki w pliku CSV';
    case ApiErrorCode.IncorrectCSVContent:
      return 'Niepoprawnea zawartość pliku CSV';
    case ApiErrorCode.PasswordsMustMatch:
      return 'Hasła muszą być identyczne';
    case ApiErrorCode.SMSSenderIsNotAvailable:
      return 'Nadawca SMS nie jest dostępny';
    case ApiErrorCode.WrongSignsOrLengthInSenderName:
      return 'Niepoprawne znaki lub zbyt długa nazwa nadawcy SMS, maks 11';
    case ApiErrorCode.YouHaveToChooseReceivers:
      return 'Musisz wybrać odbiorców';
    case ApiErrorCode.GroupNotFound:
      return 'Nie znaleziony grupy';
    case ApiErrorCode.RulesAreNeeded:
      return 'Reguły dla grupy są wymagane';
    case ApiErrorCode.CartIsEmpty:
      return 'Koszyk jest pusty';
    case ApiErrorCode.NotEnoughProductsInStock:
      return 'Niewystarczająca ilość produktów w magazynie';
    case ApiErrorCode.QueryOrParamIdIsInvalid:
      return 'Niepoprawne query albo parametr';
    case ApiErrorCode.CurrencyNotFoundInTenantConfig:
      return 'Nie znaleziono waluty w konfiguracji platformy';
    case ApiErrorCode.ThisCountryIsNotSupported:
      return 'Ten kraj nie jest wspierany';
    case ApiErrorCode.ArticleNotFound:
      return 'Nie znaleziono artykułu';
    case ApiErrorCode.QuizIsInactive:
      return 'Quiz nie jest aktywny';
    case ApiErrorCode.LoginStatusOrTokenExpired:
      return 'Dane do Facebooka wygasły lub straciły ważność';
    case ApiErrorCode.TokenExpired:
      return 'Brak aktualnych danych do Facebooka';
    case ApiErrorCode.WrongPermissionOnFbPage:
      return 'Niepoprawne uprawnienia strony na Facebooku';
    case ApiErrorCode.InvalidPublishTime:
      return 'Niepoprawna data publikacji';
    case ApiErrorCode.TokenIsNotSet:
      return 'Token nie został ustawiony';
    case ApiErrorCode.InvalidParam:
      return 'Niepoprawny parametr';
    case ApiErrorCode.SignUpForContestIsNotAvailable:
      return 'Zapisy na wydarzenie nie są dostępne';
    case ApiErrorCode.YouCantDeactiveMainAction:
      return 'Nie możesz zdezaktywować akcji głównej';
    case ApiErrorCode.MainRankingAlreadyExists:
      return 'Ranking główny już istnieje';
    case ApiErrorCode.CannotSetAddPointsToMainRanking:
      return 'Nie można dodawać punktów do rankingu głównego';
    case ApiErrorCode.TemporaryActionNotFound:
      return 'Nie znaleziono akcji czasowej';
    case ApiErrorCode.AlreadyInvited:
      return 'Ten użytkownik jest już zarejestrowany.';
    case ApiErrorCode.AlreadyInvitedByYou:
      return 'Ten użytkownik został już zaproszony przez Ciebie.';
    case ApiErrorCode.TelOrEmailRequired:
      return 'Musisz podać przynajmniej numer telefonu lub email!';
    case ApiErrorCode.IncorrectPassword:
      return 'Nieprawiddłowe hasło';
    case ApiErrorCode.NotEnoughPoints:
      return 'Nie posiadasz wystarczającej ilości punktów!'
    case ApiErrorCode.ProductLimitExceeded:
      return 'Przekroczono limit ilościowy dotyczący tego produktu'
    case ApiErrorCode.AttachmentNotFound:
      return 'Nie znaleziono załącznika'
    case ApiErrorCode.ThisUserCantBeInvited:
      return 'Ten użytkownik został już dodany i nie może zostać ponownie zaproszony';
    case ApiErrorCode.OtherError:
      return 'Wystąpił błąd';
    case ApiErrorCode.YouCantBuyMoreProducts:
        return 'Nie możesz kupić takiej ilości produktów';
    case ApiErrorCode.PasswordNeedsReset:
        return 'Musisz zresetować swoje hasło.'
    case ApiErrorCode.NewPasswordMustBeDifferent:
        return 'Nowe hasło musi się różnić od poprzedniego.'
    default:
      return 'Wystąpił błąd';
  }
};

const getErrorNotification = (text: string) => ({
  type: 'error',
  text,
  id: uuid(),
});
const getSuccessNotification = (text: string) => ({
  type: 'success',
  text,
  id: uuid(),
});

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SUCCESS_NOTIFICATION': {
      const notification = getSuccessNotification(action.payload);
      return [...state, notification];
    }
    case 'ERROR_NOTIFICATION': {
      const notification = getErrorNotification(getError(action.payload));
      return [...state, notification];
    }
    case 'ERROR_NOTIFICATION_TEXT': {
      const notification = getErrorNotification(action.payload);
      return [...state, notification];
    }
    case 'HIDE_NOTIFICATION':
      return state.filter((notification: SingleNotification) => notification.id !== action.payload);
    default:
      return state;
  }
};
